import { default as _91_46_46_46slug_93HJmYAfZ7GLMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/[...slug].vue?macro=true";
import { default as _91id_93e4hPmkzhdRMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/article/[id].vue?macro=true";
import { default as everaOo98XPfPAMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/ever.vue?macro=true";
import { default as indexDUFsZDPxDLMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/index.vue?macro=true";
import { default as privacy_45policyfDkTEX5PC1Meta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/privacy-policy.vue?macro=true";
import { default as protected_45pagesqZpZ41dUEMeta } from "/home/vsts/work/1/s/webapp-ots-nuxt/pages/protected-page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/[...slug].vue")
  },
  {
    name: "article-id",
    path: "/article/:id()",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/article/[id].vue")
  },
  {
    name: "ever",
    path: "/ever",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/ever.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/privacy-policy.vue")
  },
  {
    name: "protected-page",
    path: "/protected-page",
    meta: protected_45pagesqZpZ41dUEMeta || {},
    component: () => import("/home/vsts/work/1/s/webapp-ots-nuxt/pages/protected-page.vue")
  }
]