<script setup lang="ts">
const url = `${useCdnUrl()}/json/features/feature-list.json`;

// const { data: postList } = await useFetch(url, {
//   key: "feature-list-data",
//   method: "GET",
// });

const postList = ref([]);

onMounted(async () => {
  postList.value = await $fetch(url);
});
</script>

<template>
  <div class="posts">
    <ul>
      <PostsFeatureItem v-for="post in postList" :key="post" :url="post" />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
div.posts {
  background-color: black;
  width: 100%;

  ul {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    @include queries.for-size(tablet-landscape-down) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
