import type { TokenResponse } from "@/types/";

export default defineNuxtRouteMiddleware(async (to) => {
  function showError(error?) {
    console.error("auth check error", error);
    throw createError({
      statusCode: 500,
      statusMessage: "There was a problem signing you in. Please try again.",
      data: error || "unknown",
    });
  }

  if (import.meta.server) {
    if (to.path === "/auth-response") {
      if (to.query.error) {
        showError({ error: to.query.error, error_description: to.query.error_description });
      }

      if (to.query.code) {
        const config = useRuntimeConfig();
        try {
          const resp: TokenResponse | { error } = await $fetch(
            `/api/auth/get-token?code=${to.query.code}&redirect_url=${config.public.authCallbackUrl}}`
          );

          if ((resp as TokenResponse).access_token) {
            const accessToken = useCookie("access_token", {
              maxAge: resp.expires_in,
              secure: true,
            });
            accessToken.value = resp.access_token;

            const idToken = useCookie("id_token", {
              maxAge: resp.expires_in,
              secure: true,
            });
            idToken.value = resp.id_token;
          } else {
            // need to logout or revalidate
            showError(resp.error);
          }
        } catch (error) {
          showError(error);
        }

        return navigateTo("/");
      }
    }

    if (to.path === "/logout-response") {
      return navigateTo("/");
    }
  }
});
