<script setup lang="ts">
import type { PropType } from "vue";

defineOptions({
  inheritAttrs: false,
});

type InputType = "text" | "number" | "tel" | "email" | "password";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
  },
  type: {
    type: String as PropType<InputType>,
    default: "text",
  },
  placeholder: {
    type: String,
    default: "",
  },
  error: {
    type: Boolean,
  },
  errorMessage: {
    type: String,
    default: "error",
  },
});

const emit = defineEmits(["input"]);

const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  },
});
</script>

<template>
  <input
    v-model="model"
    :name="name"
    :type="type"
    :placeholder="placeholder"
    :required="required"
    :class="{ error: error }"
    v-bind="$attrs"
  />
  <FormsFieldError v-show="error">{{ errorMessage }}</FormsFieldError>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/forms";

input {
  @include forms.form-input-base;
}
</style>
