<script setup lang="ts">
const theHeader = ref<HTMLElement>();

const route = useRoute();
const router = useRouter();
const stayCollapsed = ref(false);
const isCollapsed = ref(route.name !== "index");

const error = useError();

onMounted(() => {
  isCollapsed.value = route.name !== "index";
  stayCollapsed.value = route.name !== "index";
  if (!stayCollapsed.value) {
    window.addEventListener("scroll", onScroll);
  }
});

watch(
  () => route.name,
  () => {
    isCollapsed.value = route.name !== "index";
    stayCollapsed.value = route.name !== "index";

    if (!stayCollapsed.value) {
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
    }
  }
);

function onScroll() {
  if (!stayCollapsed.value) {
    const scrollTop = window.scrollY;
    isCollapsed.value = scrollTop > 150;
  } else {
    window.removeEventListener("scroll", onScroll);
  }
}

function onHomeClick() {
  if (error) {
    clearError({ redirect: "/" });
  }

  if (route.name === "index") {
    const element = document.getElementById("homeBanner");
    element!.scrollIntoView({ block: "start", behavior: "smooth" });
  } else {
    router.push("/");
  }
}

onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll);
});
</script>

<template>
  <header ref="theHeader" :class="{ collapsed: isCollapsed }">
    <div class="header-container">
      <div class="brand">
        <TheNuxtLink to="" aria-label="Go to home page" @click="onHomeClick">
          <div class="ots-logo" :class="{ collapsed: isCollapsed }">
            <nuxt-img
              :src="`${useCdnUrl()}/assets/logos/ots-logo-white.svg`"
              alt="One Times Square"
              width="100%"
              height="auto"
            />
          </div>
        </TheNuxtLink>
      </div>
      <TheMenu v-if="!error" :collapsed="isCollapsed" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/queries";
$transition-time: 0.25s;
header {
  left: 50%;
  margin: auto;
  max-width: 2560px;
  padding: 2.5rem 3rem;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 1000;

  .header-container {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: var(--max-view-width);
    width: 100%;

    .brand {
      pointer-events: auto;

      a {
        cursor: pointer;
      }
    }

    .ots-logo {
      max-width: 200px;
      transition: width $transition-time ease-out;
      width: 200px;

      @include queries.for-size(tablet-landscape-down) {
        width: 100px;
      }

      img {
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.3));
        width: 100%;
      }
    }
  }

  &.collapsed {
    background-color: black;
    padding: 1.5rem 3rem;
    .ots-logo {
      height: 60px;
      width: 100px;

      @include queries.for-size(phone-only) {
        height: 50px;
        width: 70px;
      }
    }
  }
}
</style>
