<script setup lang="ts">
import { storeToRefs } from "pinia";
const { userProfile } = storeToRefs(useAuthStore());
const { login, logout, getUserProfile } = useAuthStore();

onMounted(async () => {
  nextTick(() => {
    getUserProfile();
  });
});
</script>

<template>
  <div class="profile-menu">
    <button aria-label="Open profile menu">
      <UserProfileIcon />
    </button>

    <div class="profile-menu_dropdown">
      <ul v-if="userProfile">
        <li>
          {{ userProfile.email }}
        </li>
      </ul>
      <ul>
        <li v-if="!userProfile">
          <button aria-label="Login to account" @click="login">Login</button>
        </li>
        <li v-else>
          <button aria-label="Logout of account" @click="logout">Logout</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-menu {
  position: relative;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &:hover {
    .profile-menu_dropdown {
      display: block;
    }
  }

  .profile-menu_dropdown {
    background-color: white;
    border-radius: 0.25rem;
    display: none;
    min-width: 150px;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 100%;

    ul {
      border-bottom: 1px solid black;
      list-style-type: none;
      margin: 0;
      padding: 1rem 0;

      &:last-child {
        border-bottom: none;
      }

      li {
        font-size: 1.25rem;
        text-align: left;
        button {
          color: black;
          cursor: pointer;
          background-color: lightgray;
          padding: 0.5rem;
          border-radius: 4px;
          //display: inline-block;
          text-decoration: none;
          width: 100%;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
