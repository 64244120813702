import { defineStore } from "pinia";
// import type { AuthToken, UserProfile } from "@/types/";

export const useAuthStore = defineStore("authStore", () => {
  const config = useRuntimeConfig();
  const _userProfile = ref();

  const idToken = computed(() => {
    return useCookie("id_token").value;
  });

  const accessToken = computed(() => {
    return useCookie("access_token").value;
  });

  const login = () => {
    navigateTo(
      `${config.public.authApiUrl}/login?response_type=code&client_id=${config.public.authClientId}&redirect_uri=${config.public.authCallbackUrl}/auth-response`,
      {
        external: true,
      }
    );
  };

  const logout = () => {
    useCookie("access_token").value = null;
    useCookie("id_token").value = null;

    navigateTo(
      `${config.public.authApiUrl}/logout?client_id=${config.public.authClientId}&logout_uri=${config.public.authCallbackUrl}/logout-response`,
      {
        external: true,
      }
    );
  };

  const getUserProfile = async () => {
    if (!_userProfile.value && useCookie("access_token").value) {
      const resp = await $fetch(`/api/auth/user-profile?token=${useCookie("access_token").value}`);

      if (resp.error) {
        console.error("auth-store.ts -> getUserProfileresp.error", resp.error);
        logout();
      } else {
        _userProfile.value = resp;
      }
    }
    return _userProfile.value;
  };

  const userProfile = computed(() => {
    return _userProfile.value;
  });

  return {
    idToken,
    accessToken,
    login,
    logout,
    getUserProfile,
    userProfile,
  };
});
