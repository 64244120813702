<script setup lang="ts">
const { getYear } = useUtils();
</script>

<template>
  <footer>
    <LazyTheCountdown />
    <TheSocialMenu />
    <div class="footer-content">
      <div class="footer-logos">
        <TheNuxtLink
          to="https://www.jamestownlp.com/"
          target="_blank"
          aria-label="Go to Jamestown LP website"
          analytics-target="jamestown-website"
        >
          <nuxt-img
            :src="`${useCdnUrl()}/assets/logos/jamestown-logo.svg`"
            alt="Jamestown LP"
            width="150"
          />
        </TheNuxtLink>
      </div>
      <div class="footer-connect">
        For general inquiries, contact
        <TheNuxtLink
          to="mailto:connect@onetimessquare.com"
          aria-label="Email One Times Square"
          analytics-target="email-connect"
          >connect@onetimessquare.com</TheNuxtLink
        >
      </div>
      <div class="privacy">
        <div>
          <div>&copy;{{ getYear() }} All rights reserved Jamestown L.P.</div>
          <TheNuxtLink to="/privacy-policy" aria-label="Read privacy notice"
            >Privacy Notice</TheNuxtLink
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@use "@/assets/scss/queries";
footer {
  background-color: black;
  .footer-content {
    align-items: center;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: var(--device-xl);
    max-width: 2560px;
    padding: 4rem;
    width: 100%;

    @media (max-width: 820px) {
      padding-bottom: 15rem;
    }

    @include queries.for-size(tablet-landscape-down) {
      flex-direction: column;
      font-size: 2rem;
      gap: 2rem;
      div {
        display: flex;

        flex-direction: column !important;
        text-align: center;
        width: 100%;
      }

      .footer-connect {
        font-size: 2rem !important;
      }
    }

    .footer-logos {
      align-items: center;
      display: inline-flex;
      flex: 30%;
      font-size: 1.25rem;
      gap: 6rem;

      img {
        height: 75px;
        width: auto;
      }

      @include queries.for-size(phone-only) {
        display: flex;
        flex-direction: row !important;
        gap: 5rem;
        justify-content: center;
        padding: 3rem;
        width: 100%;

        img {
          height: 40px;
        }
      }
    }

    .footer-connect {
      flex: 40%;
      font-size: 1.6rem;
      text-align: center;
    }

    .privacy {
      display: flex;
      flex: 30%;
      flex-direction: column;
      font-size: 1.25rem;
      margin-top: 1rem;

      > div {
        margin-left: auto;
      }

      a {
        text-decoration: underline !important;
        &:visited {
          color: white;
        }
      }
    }
  }
}
</style>
