<script setup lang="ts">
import type { NuxtError } from "#app";

type ErrorProps = {
  error: NuxtError;
};

defineProps<ErrorProps>();

const { login } = useAuthStore();
</script>

<template>
  <TheHeader />
  <main class="error-page">
    <section class="error-page__content">
      <h1>Error</h1>
      <h2>{{ error.statusMessage }}</h2>
      <p>{{ error.data }}</p>
      <p><TheNuxtLink to="" @click="login">Try Again</TheNuxtLink></p>
      <p><TheNuxtLink to="/">Go back home</TheNuxtLink></p>
    </section>
  </main>
  <TheFooter />
</template>

<style lang="scss">
.error-page {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0;
  margin-top: var(--header-desktop-collapse-height);
  padding: 4rem 6rem;
  width: 100%;

  .error-page__content {
    margin: auto;
    max-width: 1300px;
    min-height: 70vh;
    width: 100%;
    text-align: center;
    a {
      color: black !important;
      text-decoration: underline;
    }

    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.75rem;
    }
  }
}
</style>
